import React from "react";
import { BulkOrderStockMessage } from "./BulkOrderStockMessage";
import { isProductOrderable } from "../utilities/product_utilities";
import classNames from "classnames";
import { getStockMessageData } from "../utilities/product_utilities";

export function BulkOrderTableRow({
  product, quantity, updateQuantity, decrementQuantity, incrementQuantity, hasSize
}) {

  const product_label_styles = product.styles.slice(1).map(s => s.style_item_name);
  const product_label = product_label_styles.join(" ");
  const product_price = typeof product.price === "undefined" ? "" : '$' + product.price.toFixed(2);
  //getStockMessageData expects an array of products to account for a no product matches message
  const stock_message_data = getStockMessageData([product]);
  const is_orderable = isProductOrderable(product);

  const order_row_classname = classNames({
    "bulk-drawer__ordering-row": true,
    "bulk-drawer__ordering-row--has-alert": stock_message_data,
    "bulk-drawer__ordering-row--has-promo": product.reduced_item,
    "border-bottom-0" : stock_message_data
  });

  return (
    <>
      {product.reduced_item && (
        <tr className="bulk-drawer__promo-row">
          <td colSpan={hasSize ? 3 : 2}>
            Included in Buy More, Save More Event!
          </td>
        </tr>
      )}
      <tr className={order_row_classname}>
        {hasSize && <td>{product_label}</td>}
        <td>{product_price}</td>
        <td>
          {is_orderable && (
            <div className="qty_button_wrap input-group cf">
              <div className="input-group-prepend">
                <button
                  type="button"
                  className="button-icon decrement btn"
                  onClick={(e) => {
                    decrementQuantity(product.code);
                  }}
                >
                  <span className="icon icon-minus is-sm">Decrement</span>
                </button>
              </div>
              <input
                className="ordering_qty style_ordering-qty form-control"
                type="text"
                name={product.code}
                value={quantity}
                autoComplete="off"
                onChange={(e) => {
                  updateQuantity(product.code, e.target.value);
                }}
              />
              <div className="input-group-append">
                <button
                  type="button"
                  className="button-icon increment btn"
                  onClick={(e) => {
                    incrementQuantity(product.code);
                  }}
                >
                  <span className="icon icon-plus is-sm">Increment</span>
                </button>
              </div>
            </div>
          )}
        </td>
      </tr>

      {stock_message_data && (
        <BulkOrderStockMessage hasSize={hasSize} {...stock_message_data} />
      )}
    </>
  );
}
