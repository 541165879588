//Internal Use Helper Functions
function getProductsWithAllStyleItems(state, style_items) {
  return state.products.filter((p) => {
    let all_selections_match = true;
    style_items.forEach((selected_style_item) => {
      const matching_style = p.styles.find((ps) => {
        const style_match = ps.style_ref === selected_style_item.style_ref;
        const style_item_match =
          ps.style_item_ref === selected_style_item.style_item_ref;
        return style_item_match && style_match;
      });
      if (!matching_style) {
        all_selections_match = false;
      }
    });
    return all_selections_match;
  });
}

function getOtherStyleItemSelections(state, current_style) {
  const other_selected_styles = state.styles.filter((s) => {
    const is_not_current_style = s !== current_style;
    const is_selected = s.selected_style_item_ref !== null;
    return is_selected && is_not_current_style;
  });

  const other_selected_style_items = other_selected_styles.map((oss) => {
    return {
      style_ref: oss.style_ref,
      style_item_ref: oss.selected_style_item_ref,
    };
  });
  return other_selected_style_items;
}

function isSelectionValid(matches) {
  // valid selection if a matching product is in stock or backordered
  const available_matches_index = matches.findIndex((p) => p.in_stock || p.backordered);
  return available_matches_index > -1;
}

function isSelectionSomeBackordered(matches) {
  // If any match is backordered this is a some backordered selection
  const backordered_match_index = matches.findIndex((p) => p.backordered);
  return backordered_match_index > -1;
}

function isSelectionBackordered(matches) {
  // If some matches aren't backordered the selection is not backordered
  if(isSelectionSomeBackordered(matches) === false)
  {
    return false;
  }

  // Check for an in stock match if there is none the selection is backordered
  const in_stock_match_index = matches.findIndex((p) => p.in_stock);
  return in_stock_match_index === -1;
}

function isSelectionLowStock(matches) {  
  if(matches.length === 0)
  {
    //no matches, so not low stock
    return false;
  }

  const low_stock_index = matches.findIndex((p) => p.low_stock);
  if(low_stock_index === -1)
  {
    //no low stock match, so not low stock
    return false;    
  }

  // if no matches are in stock or preorder then all are low_stock or backordered
  // and selection is low_stock 
  const in_stock_index = matches.findIndex((p) => (p.in_stock && !p.low_stock) || p.preorder);
  return in_stock_index === -1;
}

function isSelectionReduced(matches) {  
  if(matches.length === 0)
  {
    //no matches, so not reduced
    return false;
  }

  // if a there are no non reduced matches the selection is reduced
  const non_reduced_index = matches.findIndex((p) => !p.reduced_item);
  return non_reduced_index === -1;
}


export function calculateAvailableVariants(state) {
  //If there is more than 1 style
  if (state.styles.length > 1) {
    //For each of the first styles style items
    state.styles[0].style_items.forEach((si) => {
      const available_variants = [];

      //Check if product is available in first style item and each second style item
      state.styles[1].style_items.forEach((si2) => {
        //Build array of hypothetical selections with first two style items
        const hypothetical_selected_style_items = [
          {
            style_ref: state.styles[0].style_ref,
            style_item_ref: si.style_item_ref,
          },
          {
            style_ref: state.styles[1].style_ref,
            style_item_ref: si2.style_item_ref,
          },
        ];

        //if there is a match this style item is available
        const hypothetical_matches = getProductsWithAllStyleItems(
          state,
          hypothetical_selected_style_items
        );
        
        if (hypothetical_matches.length > 0) {
          available_variants.push(
            {
              label: si2.style_item_name,
              backordered: isSelectionSomeBackordered(hypothetical_matches),
              low_stock: isSelectionLowStock(hypothetical_matches),
              reduced: isSelectionReduced(hypothetical_matches)
            }
          );
        }
      });

      //Set available_variants if available
      if (available_variants.length > 0) {
        si.available_variants = available_variants;
      }
    });
  }
}


export function updateStyleItemState(state) {
  state.styles.forEach((current_style) => {
    //Get array of current selections excluding the current style
    const other_selected_style_items = getOtherStyleItemSelections(
      state,
      current_style
    );

    //Update each style for the current style
    current_style.style_items.forEach((current_style_item) => {
      //Build array of hypothetical selections which is the style items
      //selections for other styles plus the current style item
      const hypothetical_selected_style_items =
        other_selected_style_items.concat({
          style_ref: current_style.style_ref,
          style_item_ref: current_style_item.style_item_ref,
        });

      //Get products where all the hypothetical selections match
      const hypothetical_matches = getProductsWithAllStyleItems(
        state,
        hypothetical_selected_style_items
      );

      //Update style item state based on matches with the current style item selected
      current_style_item.valid = isSelectionValid(hypothetical_matches);
      current_style_item.backordered = isSelectionBackordered(hypothetical_matches);
      current_style_item.some_backordered = isSelectionSomeBackordered(hypothetical_matches);
      current_style_item.low_stock = isSelectionLowStock(hypothetical_matches);
      current_style_item.reduced = isSelectionReduced(hypothetical_matches);
    });
  });
}

export function sortStyleItems(state) {
  if (state.style_sort_order) {
    state.styles.forEach((s) => {
      const style_sort_order = state.style_sort_order.styles.find(
        (style_sort) => style_sort.style === s.style_ref
      );
      if (style_sort_order?.items) {
        s.style_items.sort((a, b) => {
          const a_sort_index = style_sort_order.items.findIndex(
            (sorted_style_item_ref) =>
              a.style_item_ref === sorted_style_item_ref
          );
          const b_sort_index = style_sort_order.items.findIndex(
            (sorted_style_item_ref) =>
              b.style_item_ref === sorted_style_item_ref
          );
          return a_sort_index - b_sort_index;
        });
      }
    });
  }
}

export function calculateInitialBulkOrderingState(state) {
  //If there are styles
  if (state.styles.length > 0) {
    //For each of the first styles style items
    state.styles[0].style_items.forEach((si) => {
      //get hypothetical matches
      const hypothetical_selected_style_items = [{
        style_ref: state.styles[0].style_ref,
        style_item_ref: si.style_item_ref,
      }];

      const hypothetical_matches = getProductsWithAllStyleItems(
        state,
        hypothetical_selected_style_items
      );

      //check matches
      si.bulk_ordering_state = {
        backordered: isSelectionBackordered(hypothetical_matches),
        low_stock: isSelectionLowStock(hypothetical_matches),
        reduced: isSelectionReduced(hypothetical_matches)
      }
    });
  }
}

export function autoSelectSubsequentStylesIfOneProductMatches(state,selected_style_ref) {
  // build hypothetical selections with current selections up to the selected style
  const hypothetical_selections = [];
  for(let i = 0; i < state.styles.length; i++)
  {
    const is_selected_style = state.styles[i].style_ref === selected_style_ref;
    if(state.styles[i].selected_style_item_ref !== null)
    {
      hypothetical_selections.push({
        style_ref: state.styles[i].style_ref,
        style_item_ref: state.styles[i].selected_style_item_ref
      });
    }
    if(is_selected_style)
    {
      // ignore any selections after the the current selection
      break;
    }
  }

  //Get products where all the hypothetical selections match
  const hypothetical_matches = getProductsWithAllStyleItems(
    state,
    hypothetical_selections
  );


  // If exactly one product matches set all style selections to the matching products style items
  if (hypothetical_matches.length === 1) {
    state.styles.forEach(s => {
      const matching_style = hypothetical_matches[0].styles.find(ps => {
        return ps.style_ref === s.style_ref;
      });
      if(matching_style)
      {
        s.selected_style_item_ref = matching_style.style_item_ref;
        s.style_items.forEach((style_item) => {
          style_item.selected =
            style_item.style_item_ref === s.selected_style_item_ref;
        });
      }
    });
  }
}

export function autoDeselectSubsequentStyles(state, deselected_style_ref) {
  let after_deselected_style = false;
  // iterate styles, deselect all styles after the matching the deslected_style_ref
  state.styles.forEach(s => {
    if(after_deselected_style) {
      // deselect the style 
      s.selected_style_item_ref = null;
      // update the style items selected state
      s.style_items.forEach((style_item) => {
        style_item.selected =
          style_item.style_item_ref === s.selected_style_item_ref;
      });
    }
    else
    {
      if(s.style_ref === deselected_style_ref)
      {
        after_deselected_style = true;
      }
    }
  });
}