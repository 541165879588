import classNames from "classnames";

export function StyleItemListItem({ styleItem, children, styleImages }) {
  const base_classes = ["style_ordering-style_row-cell", "mb-2"];
  const state_classes = {
    "is-selected": styleItem.selected,
    "is-thumb": styleImages,
    "is-valid": styleItem.valid,
    "is-invalid": !styleItem.valid,
    "is-backordered": styleItem.backordered,
    "is-low-stock": styleItem.low_stock,
    "is-reduced": styleItem.reduced,
  };  
  const li_class_name = classNames(base_classes, state_classes);

  return (
    <li className={li_class_name} key={styleItem.style_item_ref}>
      {children}
    </li>
  );
}
