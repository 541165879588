import classNames from "classnames";

function BulkOrderingAvailableVariantsItem({ variant }) {
  const class_name = classNames({
    "color-browser-card__size": true,
    "color-browser-card__size--is-backordered": variant.backordered,
    "color-browser-card__size--is-low-stock": variant.low_stock,
    "color-browser-card__size--is-reduced": variant.reduced
  });

  return (
    <li className={class_name}>
      {variant.label}
      {(variant.low_stock || variant.reduced) && (
        <div className="color-browser-card__size-icons">
          {variant.low_stock && (
            <span
              className="color-browser-card__size-icon color-browser-card__size-icon--is-low-stock"
              title="Low Stock"
            ></span>
          )}
          {variant.reduced && (
            <span
              className="color-browser-card__size-icon color-browser-card__size-icon--is-reduced"
              title="Buy More, Save More"
            ></span>
          )}
        </div>
      )}
    </li>
  );
}

export function BulkOrderingAvailableVariants({ variants }) {
  return (
    <>
      <div className="color-browser-card__sizes-label">Available In:</div>
      <ul className="color-browser-card__sizes-list">
        {variants.map((variant, index) => (
          <BulkOrderingAvailableVariantsItem key={index} variant={variant} />
        ))}
      </ul>
    </>
  );
}

