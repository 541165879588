export function StyleItemButton({ children, className, onClick, styleItem}) {
  return (
    <button onClick={onClick} className={className} type="button">
      {children}
      {(styleItem.low_stock || styleItem.reduced) && (
        <div className="style_ordering-button_icons">
          {styleItem.reduced && (
            <span
              className="style_ordering-button_icon style_ordering-button_icon--is-reduced"
              title="Buy More, Save More"
            ></span>
          )}
          {styleItem.low_stock && (
            <span
              className="style_ordering-button_icon style_ordering-button_icon--is-low-stock"
              title="Low Stock"
            ></span>
          )}
        </div>
      )}
    </button>
  );
}
